import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { Head } from '../components/templates/head';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';

import RestartIcon from '../assets/restart.svg';
import HomeIcon from '../assets/home.svg';

// In the location state passed through here you will need to pass the
// text to complete the heading as it can change depending on the response
// type selected
const CompletePage = ({ location }) => {
	const headingEnd = location?.state?.headingEnd || '';
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="flex flex-col min-safe-h-screen">
				<NextStepHeader
					title="Thank you for the extra details"
					subtitle={`One of our advisors will review these and contact
								you ${headingEnd || ''}`}
					restartBtn={
						<Button
							variant="White"
							className="!py-xxs-f !px-l-f"
							onClick={() => navigate('/')}
						>
							<RestartIcon className="mr-2" />
							Restart
						</Button>
					}
				/>
				<section className="p-4 bg-brand-pale-300">
					<div className="mx-auto wrapper-small">
						<CloseButton
							variant="Main"
							destination="https://www.peninsulagrouplimited.com/"
							id="peninsulaComplete"
						>
							<HomeIcon className="mr-2" />
							Peninsula
						</CloseButton>
					</div>
				</section>
			</main>
		</>
	);
};

CompletePage.defaultProps = {
	location: undefined,
};

CompletePage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export default CompletePage;
